<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <relatorios-navigation-tabs
          :relatoriosModelos="modelosChildren"
          :selectedTab.sync="selectedTabNavegationTabs"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <relatorios-data-table
          ref="relatoriosDataTable"
          :titulo="tituloTabela"
          :items="items"
          :loading="loading"
          @rechargeTable="chargeTableDecision(tipoListaRelatorio)"
          @click-novo-relatorio:triggered="openDialogNovoRelatorio()"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="350"
      >
        <v-card>
          <v-card-title class="headline">Novo relatório</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-select
                  v-model="dialogsData.tipoRelatorio"
                  @change="
                    (dialogsData.grupoRelatorio = ''),
                      (dialogsData.tipoRelatorioModelo = '')
                  "
                  label="Tipo de Relatório"
                  :items="itensTipoRelatorio"
                  filled
                />
              </v-row>
              <v-row>
                <v-select
                  v-if="dialogsData.tipoRelatorio == 'Usar Modelo'"
                  v-model="dialogsData.grupoRelatorio"
                  @change="
                    setModelosTemplatesDialogByGroup(
                      dialogsData.grupoRelatorio.id
                    )
                  "
                  label="Grupo"
                  :items="modelosChildrenAvailable"
                  item-text="titulo"
                  item-value="id"
                  return-object
                  filled
                />
              </v-row>
              <v-row>
                <v-select
                  v-if="
                    dialogsData.grupoRelatorio &&
                    dialogsData.tipoRelatorio == 'Usar Modelo'
                  "
                  v-model="dialogsData.tipoRelatorioModelo"
                  :items="modelosTemplateDialog"
                  item-text="titulo"
                  item-value="id"
                  return-object
                  label="Template"
                  filled
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="disabledBtnDialog"
              @click="dialogRedirect()"
            >
              Prosseguir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import RelatoriosService from '@/services/RelatoriosService';
import GruposRelatoriosService from '@/services/GruposRelatoriosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],
  components: {
    RelatoriosNavigationTabs: () =>
      import('@/components/relatorios/RelatoriosNavigationTabs'),
    RelatoriosDataTable: () =>
      import('@/components/relatorios/RelatoriosDataTable')
  },
  data: () => ({
    userIsAdmin: false,
    items: [],
    dialog: false,
    tituloTabela: 'Relatórios',
    modelosChildren: [],
    modelosTemplateDialog: [],
    dialogsData: {
      tipoRelatorio: 'Padrão',
      grupoRelatorio: '',
      tipoRelatorioModelo: ''
    },
    selectedTabNavegationTabs: 0,
    arrTour: [
      {
        element: '#tab-historico',
        intro:
          'Selecionando este menu você poderá acessar todas os relatórios cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#tab-favoritos',
        intro:
          'Selecionando esta opção você poderá acessar os relatórios que você definir como seus FAVORITOS.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#tab-modelos',
        intro:
          'Selecionando esta opção você consegue listar relatorios criados a partir de modelos predefinidos e criar novos utilizando-os.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#btn-novo-relatorio',
        intro:
          'Clicando sobre este botão você poderá criar novos relatórios padrões ou relatórios a partir de modelo predefinidos.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#relatorios-historico-search',
        intro:
          'Neste campo você poderá pesquisar por relatórios já cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-download-rel',
        intro:
          'Clicando sobre este botão você poderá fazer o download em .XLSX do resultado de seu relatório.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-edit-rel',
        intro:
          'Clicando sobre este botão você poderá editar seu relatório, incluindo, alterando ou excluindo campos e filtros.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-favoritar-rel',
        intro:
          'Clicando sobre este botão você poderá marcar este relatório como favorito, podendo acessá-lo mais rapidamente através do menu superior "FAVORITOS".',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-rel',
        intro:
          'Aqui você acessa outras opções para seu relatório, como executá-lo novamente, duplicá-lo, deletá-lo e é possivel visualizar informações de criação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),
  created() {
    this.getUserIsAdmin();
    this.getGruposRelatorios();
    this.chargeTableDecision(this.tipoListaRelatorio);
  },
  computed: {
    modelosChildrenAvailable() {
      return this.modelosChildren.filter(
        (modelo) => modelo.relatorios_vinculados.length
      );
    },
    disabledBtnDialog() {
      let disabled = false;
      let dd = this.dialogsData;
      if (dd.tipoRelatorio === 'Padrão' || dd.tipoRelatorio === 'Customizado') {
        disabled = false;
      } else if (dd.grupoRelatorio === '' || dd.tipoRelatorioModelo === '') {
        disabled = true;
      }
      return disabled;
    },
    tipoListaRelatorio() {
      return this.$store.getters.getTipoListaRelatorio;
    },
    itensTipoRelatorio() {
      return !this.userIsAdmin
        ? ['Padrão', 'Usar Modelo']
        : ['Padrão', 'Usar Modelo', 'Customizado'];
    }
  },
  methods: {
    getUserIsAdmin() {
      AuthService.userIsAdmin().then(
        (userIsAdmin) => (this.userIsAdmin = userIsAdmin)
      );
    },
    getRelatorios() {
      this.items = [];
      this.loading = true;
      RelatoriosService.index()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },
    getRelatoriosFavoritados() {
      this.items = [];
      this.loading = true;
      RelatoriosService.getRelatoriosFavoritados()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },
    chargeTableDecision(newDecisionInfo) {
      if (this.$refs.relatoriosDataTable) {
        this.$refs.relatoriosDataTable.page = 1;
      }
      this.tituloTabela = newDecisionInfo.titulo;
      switch (newDecisionInfo.tipo) {
        case 'favoritos':
          this.selectedTabNavegationTabs = 1;
          this.cancelRefreshData();
          this.refreshData(this.getRelatoriosFavoritados);
          break;
        case 'modelos':
          this.selectedTabNavegationTabs = 2;
          this.cancelRefreshData();
          this.refreshData(this.getRelatoriosFilhosByModeloId);
          break;
        case 'historico':
        default:
          this.selectedTabNavegationTabs = 0;
          this.cancelRefreshData();
          this.refreshData(this.getRelatorios);
          break;
      }
    },
    getGruposRelatorios() {
      GruposRelatoriosService.getGruposRelatorios().then((response) => {
        this.modelosChildren = response.data.data;
      });
    },
    getRelatoriosFilhosByModeloId() {
      this.items = [];
      this.loading = true;
      RelatoriosService.getRelatoriosFilhosByModeloId(
        this.tipoListaRelatorio.relatorio_modelo_id
      )
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },
    setModelosTemplatesDialogByGroup(grupo) {
      this.modelosChildren.forEach((element) => {
        if (element.id === grupo) {
          element.relatorios_vinculados.forEach((element2) => {
            this.modelosTemplateDialog.push({
              id: element2.id,
              titulo: element2.nome
            });
          });
        }
      });
    },
    limparInfoDialogNovoRelatorio() {
      this.dialogsData.tipoRelatorio = 'Padrão';
      this.dialogsData.grupoRelatorio = this.dialogsData.tipoRelatorioModelo =
        '';
    },
    setInfoToDialogNovoRelatorio(objInfo) {
      this.dialogsData.tipoRelatorio = 'Usar Modelo';
      this.dialogsData.grupoRelatorio = objInfo.grupo;
      this.setModelosTemplatesDialogByGroup(objInfo.grupo);
      this.dialogsData.tipoRelatorioModelo = this.modelosTemplateDialog.find(
        (item) => item.id == objInfo.relatorio_modelo_id
      );
    },
    dialogRedirect() {
      if (this.dialogsData.tipoRelatorio === 'Padrão') {
        this.$router.push('edit');
      } else if (
        this.dialogsData.tipoRelatorio === 'Customizado' &&
        this.userIsAdmin
      ) {
        this.$router.push('customizado/edit');
      } else if (this.dialogsData.tipoRelatorioModelo) {
        this.$router.push(`modelo/${this.dialogsData.tipoRelatorioModelo.id}`);
      }
      this.dialog = false;
    },
    openDialogNovoRelatorio() {
      let infoDecision = this.tipoListaRelatorio;
      switch (infoDecision.tipo) {
        case 'favoritos':
        case 'historico':
          this.dialogsData.tipoRelatorio = 'Padrão';
          this.dialogsData.grupoRelatorio =
            this.dialogsData.tipoRelatorioModelo = '';
          break;
        case 'modelos':
          this.setInfoToDialogNovoRelatorio(infoDecision);
          break;
        default:
          break;
      }
      this.dialog = true;
    },
    iniciarTourPagina() {
      if (this.tourFuncCalled) return;
      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 5);
      this.iniciarTour(arrTourFormatted);
    }
  },
  watch: {
    tipoListaRelatorio(newValue) {
      this.chargeTableDecision(newValue);
    }
  }
};
</script>
